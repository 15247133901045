<template>
  <div>
    <div v-if="hideDiv">
      <PageLoader></PageLoader>
    </div>
    <div
      class="recent-activities-holder"
      v-if="userInvestmentsTransactions.length > 0"
    >
      <h6 class="recent-text">Recent Activites</h6>
      <div>
        <div
          class="activity-holder"
          v-for="(transaction, index) in userInvestmentsTransactions"
          :key="index"
        >
          <div class="activity-right">
            <img
              src="../../assets/icons/notification-icon.svg"
              alt="notification"
            />
            <div>
              <h6>{{ transaction.type }}</h6>
              <h5>{{ transaction.created_at | moment }}</h5>
            </div>
          </div>
          <div class="left-activity">
            <h5>₦{{ Number(transaction.amount).toLocaleString() }}</h5>
          </div>
        </div>
        <Pagination
          class="mt-5"
          v-if="userInvestmentsTransactions"
          @pageData="dataFromChild2($event)"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
const Api = new Service();
import $ from "jquery";
import PageLoader from "../PageLoader.vue";
import Pagination from "../Pagination.vue";
import moment from "moment";
export default {
  name: "InvestmentTransactions",
  components: {
    Pagination,
    PageLoader
  },
  // props: ["cooporativeData"],
  data() {
    return {
      loading: false,
      page: 1,
      limit: 10,
      hideDiv: false,
      userInvestmentsTransactions: [],
    };
  },
  watch: {},
  // computed: {
  //   welcome() {
  //     return `${this.cooporativeData}`;
  //   }
  // },
  created() {
    this.$root.$refs.fwi = this;
  },
  mounted() {
    this.investmentData();
  },
  methods: {
    dataFromChild2(data) {
      this.userInvestmentsTransactions = data.data.wallettrans;
    },
    pageUrlGlobal() {
      Api.pushPageURL(`getcooperativetransactions`);
    },

    investmentData() {
      this.hideDiv = true;
      Api.getRequest(`getusercooperativedata`)
        .then(res => {
          // console.log("Invest data", res);
          this.hideDiv = false;
          this.pageUrlGlobal();
          this.userInvestmentsTransactions = res.data.wallettrans;
          this.cooporativeData = res.data;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.hideDiv = false;
          this.$store.commit("setApiFailed", "Error occured");
        });
    },
   
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    moment2: function(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    moment3: function(date) {
      return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("MMMM Do YYYY");
    }
  }
};
</script>


<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$gray: #d7d7d7;
$yellow: #ffb100;
$shelta-black: #2b3352;
$shelta-grey: #555c74;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

// Recent Activity starts
.recent-activities-holder {
  padding-top: 30px !important;
  background: $secondary;
  border-radius: 4px;
  padding: 14px;
  // display: flex;
  // justify-content: space-between;

  .recent-text {
    color: $shelta-grey;
    font-family: Lato;
    font-weight: 500;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.see-more {
  color: #8c91a1;
  font-family: Lato;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
}
a {
  text-decoration: none;
}

.fa-chevron-right:before {
  font-size: small;
}
.fa-chevron-right {
  color: #8c91a1;
}

.activity-holder {
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 15px;
}

.activity-right {
  display: flex;
  gap: 8px;

  h6,
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 21px;
    color: $shelta-grey;
    text-align: left;
    margin-bottom: 0px;
  }

  // h6 {
  //   margin-bottom: 2px;
  // }
  // h5 {
  //   margin-bottom : 0px;
  // }
}

.left-activity {
  h5 {
    color: $shelta-grey;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 15.5px;
    margin-bottom: 2px;
  }
}

// Recent Activity ends
</style>
